import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import DonateHeader from "../../components/DonateHeader";
import Nav from "../../components/Nav";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Donate = () => {
  const [slctd, setSlctd] = useState(null);
  const [otherAmount, setOtherAmount] = useState("");
  const [paymentType, setPaymentType] = useState("one-time");

  const updateAmount = (e) => {
    const amt = Number(e.target.id);
    setOtherAmount(amt);
    if (slctd === amt) {
      setSlctd(null);
    } else {
      setSlctd(amt);
    }
  };

  const getButtonClass = (buttonAmount) => {
    return slctd === buttonAmount ? "bg-red-500 shadow-lg" : "bg-dark-blue";
  };

  const handleOtherAmountChange = (e) => {
    // Use a regular expression to remove non-numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");

    setOtherAmount(numericValue);
  };

  const handleOtherButtonClick = () => {
    if (slctd !== null) {
      // Clear selection if a button is already selected
      setSlctd(null);
    }
  };

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
  };

  <div>
    <Nav />
    <DonateHeader />
    <section className="px-3 sm:px-0 sm:container mx-auto lg:px-16 py-3">
      <form>
        <h3 className="text-center text-3xl my-4">
          Select an Amount <span className="text-red-500">*</span>
        </h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <button
            id="50"
            One-Time
            onClick={updateAmount}
            className={`${getButtonClass(
              50
            )} p-10 rounded-2xl text-center text-white text-xl hover:shadow-lg`}
            type="button"
          >
            $50
          </button>
          <button
            id="100"
            onClick={updateAmount}
            className={`${getButtonClass(
              100
            )} p-10 rounded-2xl text-center text-white text-xl hover:shadow-lg`}
            type="button"
          >
            $100
          </button>
          <button
            id="200"
            onClick={updateAmount}
            className={`${getButtonClass(
              200
            )} p-10 rounded-2xl text-center text-white text-xl hover:shadow-lg`}
            type="button"
          >
            $200
          </button>
          <button
            id="250"
            onClick={updateAmount}
            className={`${getButtonClass(
              250
            )} p-10 rounded-2xl text-center text-white text-xl hover:shadow-lg`}
            type="button"
          >
            $250
          </button>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 py-3">
          <button
            id="500"
            onClick={updateAmount}
            className={`${getButtonClass(
              500
            )} p-10 rounded-2xl text-center text-white text-xl hover:shadow-lg`}
            type="button"
          >
            $500
          </button>
          <button
            id="1000"
            onClick={updateAmount}
            className={`${getButtonClass(
              1000
            )} p-10 rounded-2xl text-center text-white text-xl hover:shadow-lg`}
            type="button"
          >
            $1000
          </button>
          <button
            onClick={handleOtherButtonClick}
            className={`${
              slctd === null ? "bg-red-500 shadow-lg" : "bg-dark-blue"
            } p-10 rounded-2xl text-center text-white text-xl hover:shadow-lg`}
            type="button"
          >
            Other
          </button>
        </div>
        {slctd === null ? (
          <div className="text-center mt-4">
            <input
              type="text"
              placeholder="Enter Amount"
              value={`$ ${otherAmount}`} // Include the dollar sign here
              onChange={handleOtherAmountChange}
              className="p-3 border rounded-xl text-2xl"
            />
          </div>
        ) : null}
        <div className="text-center pt-3">
          <h3 className="inline sm:text-xl my-4">Payment Type:</h3>
          <div className="inline-flex justify-center items-center">
            <label htmlFor="one-time" className="mx-2">
              <input
                type="radio"
                id="one-time"
                name="payment-type"
                value="One-time"
                checked={paymentType === "One-time"}
                onChange={handlePaymentTypeChange}
                className="trasform scale-150"
              />
              <span className="px-1">One-Time</span>
            </label>
            <label htmlFor="monthly">
              <input
                type="radio"
                id="monthly"
                name="payment-type"
                value="Monthly"
                checked={paymentType === "Monthly"}
                onChange={handlePaymentTypeChange}
                className="trasform scale-150"
              />
              <span className="px-1">Monthly</span>
            </label>
          </div>
          <p>You selected: {paymentType} payment</p>
        </div>
        <div className="text-center sm:text-xl py-3">
          <label htmlFor="transaction-fee" className="pr-2">
            Add 3% to cover the transaction fee?
          </label>
          <input
            type="checkbox"
            name="transaction-fee"
            id="transaction-fee"
            className="transform scale-150"
          />
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="font-bold text-lg p-3 px-10 text-white bg-yellow-400 rounded-md baseline hover:bg-red-600 transition duration-300 ease-in-out mt-2 mr-4"
          >
            Submit
          </button>
        </div>
      </form>
    </section>
  </div>;
};

export default Donate;

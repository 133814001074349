import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Nav(props) {
  const [textcol, setTextCol] = useState({});
  const [logolink, setLogoLink] = useState({});
  const [isClosed, setIsClosed] = useState("hidden");
  const [scrolling, setScrolling] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Use boolean for isOpen

  // WWD - What We Do
  const handleWWDDropDown = () => {
    setIsOpen(!isOpen); // Toggle isOpen between true and false
  };

  useEffect(() => {
    if (props.color === "black") {
      setTextCol("text-black");
      setLogoLink("/images/logo-black.svg");
    } else {
      setTextCol("text-white");
      setLogoLink("/images/logo.svg");
    }
  }, [props]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const whatWeDoMenu = (
    <div
      className={`absolute top-14 right-80 mt-2 p-2 w-48 bg-white rounded-md text-md
      shadow-xl z-10 space-y-3 font-bold ${
        isOpen ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[-10px]"
      } transition-transform duration-300 ease-in-out`}
    >
      <Link
        to="/blog"
        className="block px-4 py-2 capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out hover:text-white rounded-md"
      >
        Blogs
      </Link>
      <Link
        to="/events"
        className="block px-4 py-2 capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out
            hover:text-white rounded-md"
      >
        Events
      </Link>
      {/* <Link
        to="/gallery"
        className="block px-4 py-2 capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out
            hover:text-white rounded-md"
      >
        Gallery
      </Link> */}
    </div>
  );

  const hamburger = (
    <div
      className={`absolute top-14 right-4 mt-2 p-2 w-48 bg-white rounded-md
      shadow-xl z-10 space-y-3 font-bold ${isClosed}`}
    >
      <Link
        to="/about"
        className="block px-4 py-2 text-sm capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out            hover:text-white rounded-md"
      >
        About Us
      </Link>
      <Link
        to="/blog"
        className="block px-4 py-2 text-sm capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out            hover:text-white rounded-md"
      >
        Blogs
      </Link>
      <Link
        to="/events"
        className="block px-4 py-2 text-sm capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out
            hover:text-white rounded-md"
      >
        Events
      </Link>
      {/* <Link
        to="/gallery"
        className="block px-4 py-2 text-sm capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out
            hover:text-white rounded-md"
      >
        Gallery
      </Link> */}
      <a
        href="https://buy.stripe.com/7sI28tfO42US2as3cc"
        target="_blank"
        rel="noreferrer noopener"
        className="block px-4 py-2 text-sm capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out
            hover:text-white rounded-md"
      >
        Support
      </a>
    </div>
  );

  const HandleHamburger = () => {
    if (isClosed === "hidden") {
      console.log("works");
      setIsClosed("block");
    } else {
      setIsClosed("hidden");
    }
  };

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-10 backdrop-blur-sm h-16 transition-all duration-300 ${
        scrolling ? 'bg-dark-blue shadow-md' : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto flex items-center justify-between h-fit">
        <div className="py-1 md:py-1 pl-2">
          <Link to="/">
            <img
              src={`${logolink}`}
              alt="Impact Youtheens Logo"
              // className="w-7/12 sm:w-full"
              className="md:w-10/12 w-11/12 h-auto"
            />
          </Link>
        </div>
        <div className="hidden md:flex space-x-6 text-white">
          <Link
            to="/about"
            className={`font-bold p-3 hover:text-yellow-400 transition duration-300 ease-in-out ${textcol}`}
          >
            About Us
          </Link>
          <button
            onClick={handleWWDDropDown}
            className={`font-bold p-3 hover:text-yellow-400 transition duration-300 ease-in-out ${textcol}`}
          >
            What We Do ⏷{whatWeDoMenu}
          </button>
          <a
            href="https://buy.stripe.com/7sI28tfO42US2as3cc"
            target="_blank"
            rel="noreferrer noopener"
            className="font-bold p-3 my-auto pt-2 bg-yellow-400 rounded-md baseline hover:bg-red-600 transition duration-300 ease-in-out"
          >
            Support Us
          </a>
          <div className="space-x-5 my-auto">
          <a href="https://facebook.com/impactyoutheens">
              <img className="inline" src="images/Facebook_Logo.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/impact_youtheenscanada/">
              <img className="inline" src="images/Instagram_Logo.svg" alt="" />
            </a>
            <a href="https://www.youtube.com/@rainbowyoutheensministry4201">
              <img className="inline" src="images/Youtube_Logo.svg" alt="" />
            </a>
          </div>
          {/* <Link
            to="/login"
            className="font-bold p-3 my-auto pt-2 bg-yellow-400 rounded-md baseline hover:bg-red-600 transition duration-300 ease-in-out"
          >
            Login
          </Link> */}
        </div>
        <button onClick={HandleHamburger} className="block md:hidden pr-2">
          <img src="/images/hamburger.svg" alt="" />
          {hamburger}
        </button>
      </div>
    </nav>
  );
}

export default Nav;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import jsonData from "../../data/blog.json";
import { daysCountDown } from "../../utils/dayscountdown";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { RWebShare } from "react-web-share";

function SingleBlog() {
  const { id } = useParams();
  const blogs = jsonData.blogs;
  const [blog, setBlog] = useState({});
  const formattedString = daysCountDown(blog.datetime);
  const currentUrl = window.location.href;
  const sharetitle = blog.title;
  const imageLinks = blog.extraImages;

  useEffect(() => {
    let blog = blogs.find((item) => item.id === parseInt(id));
    if (blog) {
      setBlog(blog);
    }
  }, [id, blogs]); // Include id and blogs in the dependency array

  console.log(blog);

  return (
    <section>
      <Nav color="black" />
      <div className="w-11/12 md:w-9/12 mx-auto space-y-6 pt-24 pb-8">
        {blog.img && (
          <img
            src={`/${blog.img}`}
            alt={`${blog.title}`}
            className="rounded-3xl h-96 w-full mx-auto object-center object-cover shadow-lg"
          />
        )}
        <div className="flex justify-between">
          <p className="">
            By <b>{blog.author}</b>
          </p>
          <p className="">{blog.minsRead} mins read</p>
          <p className="">{formattedString}</p>
        </div>
        <h2 className="text-2xl font-bold">{blog.title}</h2>
        <p className="whitespace-pre-line">{blog.text}</p>
        {blog.extraImages && (
          <div className={`grid sm:grid-cols-3 grid-cols-1 gap-4`}>
            {imageLinks.map((imagelink, index) => (
              <img className="rounded-lg" src={`/${imagelink}`} key={index} alt={`${blog.title} ${index}`} />
            ))}
          </div>
        )}

        <div>
          <RWebShare
            data={{
              text: { sharetitle },
              url: { currentUrl },
              title: `Impact Youtheens Blog`,
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <button className="font-bold text-lg">
              Share{" "}
              <img
                src="/images/share.png"
                alt="Share Blog"
                className="inline"
              />
            </button>
          </RWebShare>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default SingleBlog;

import React from "react";
import { Link } from "react-router-dom";
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import jsonData from '../../data/blog.json';
import BlogItem from '../../components/BlogItem'

function Home() {
  const latestThree = jsonData.blogs.slice(0, 3);

  return (
    <div>
      <Nav color="white" />
      <Header />
      <section className="block md:hidden bg-dark-blue">
        <div className="block space-y-8 container mx-auto lg:px-16 py-8">
          <div
            className="w-5/6 h-80 mx-auto rounded-lg bg-slate-100 text-white px-8 text-center py-6"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
          >
            <img className="mx-auto" src="images/mission.svg" alt="" />
            <h5 className="font-bold text-xl my-4">Embrace Impact</h5>
            <p className="mb-9">
              Discover the transformative power of embracing impact. Know more
              today.
            </p>
            <div>
              <Link
                to="/about"
                className="font-bold p-3 pt-2 border-2 border-dark-blue hover:bg-dark-blue rounded-md baseline mt-6 transition duration-300 ease-in-out"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div
            className="w-5/6 h-80 mx-auto rounded-lg bg-slate-100 text-white px-8 text-center py-6"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
          >
            <img src="images/charity.svg" alt="" className="mx-auto" />
            <h5 className="font-bold text-xl my-4">Volunteer</h5>
            <p className="mb-9">
              Together, let's inspire, support, and empower the youth.
            </p>
            <a
              href="https://forms.gle/VtvKvErdWqExRHYi7"
              target="_blank"
              rel="noreferrer noopener"
              className="font-bold p-3 pt-2 border-2 border-yellow-400 hover:bg-yellow-400 rounded-md baseline mt-6 transition duration-300 ease-in-out"
            >
              Volunteer
            </a>
          </div>
          <div
            className="w-5/6 h-80 mx-auto rounded-lg bg-slate-100 text-white px-8 text-center py-6"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
          >
            <img src="images/charity.svg" alt="" className="mx-auto" />
            <h5 className="font-bold text-xl my-4">Connect with Us</h5>
            <p className="mb-9">
              Connect with us today and be a part of something incredible!
            </p>
            <a
              href="#contactus"
              className="font-bold p-3 pt-2 border-2 border-dark-blue hover:bg-dark-blue rounded-md baseline mt-6 transition duration-300 ease-in-out"
            >
              Contact Us
            </a>
          </div>
        </div>
      </section>
      <section className="block md:grid grid-cols-2 gap-12 container mx-auto lg:px-16">
        <div>
          <img
            src="images/Groupofyouth.png"
            alt=""
            className="my-6 mx-auto  w-11/12 md:w-full"
          />
        </div>
        <div className="space-y-4 my-auto md:pr-32 md:px-0 px-4">
          <h3 className="text-4xl font-bold">
            Join Forces, <span className="text-red-700">Partner with Us!</span>
          </h3>
          <p className="leading-8">
            We believe in the power of collaboration to create positive change.
            If you share our passion for empowering young people and making a
            lasting impact, let's partner together. By joining forces, we can
            amplify our efforts, pool our resources, and reach even more lives.
            Together, we can make a difference that truly matters. Connect with
            us today and let's embark on this transformative journey together.
          </p>
          <div>
            <a
              href="https://facebook.com/impactyoutheens"
              className="font-bold p-3 pt-2 text-white bg-yellow-400 rounded-md baseline hover:bg-red-800 text-center mt-6 mr-4"
            >
              Partner
            </a>
          </div>
        </div>
      </section>
      <section className="md:container mx-auto lg:px-16">
        <h3 className="text-4xl font-bold my-7 px-4 md:px-0">Upcoming Event</h3>
        <div className="block md:grid grid-cols-2 gap-8 justify-between md:space-x-12">
          <div className="flex justify-start md:justify-between px-4 md:px-0 space-x-3">
            <div className="w-1/4">
              <h2 className="text-4xl font-bold">APR</h2>
              <div className="h-1 w-2/4 bg-red-500 rounded-full my-3"></div>
              <h1 className="text-6xl font-bold">6</h1>
            </div>
            <div className="w-full mx-auto h-56 sm:h-72 md:h-80">
              <img
                src="images/youthhubnew.png"
                alt="Youth Hub"
                className="w-fit h-full object-cover rounded-xl shadow-lg mx-auto"
              />
            </div>
          </div>
          <div className="w-11/12 mx-auto pt-5 md:pt-0">
            <h4 className="font-bold text-3xl">
              YOUTHHUB
            </h4>
            {/* <p className="leading-8">
              <b>Categories for Entry:</b> IT, General Business, and Creativity.
            </p> */}
            <p className="leading-8">
              <b className="text-red-600">164 ROCHEFORT STREET, KITCHENER N2R 1V7, ONTARIO.</b>
              <b> | </b>
              <b className="text-red-600">12:00PM</b>
              <b> | </b>
              <b className="text-red-600">Saturday, 6th April, 2023</b>
            </p>
            <p className="leading-8">
            "Bill Watterson never made a mistake when he said “Things are never as quite as scary when you've got a family”
              <br />
              Click the link to register: <a className="text-blue-600" href="https://forms.gle/MhTPQtRd5RfJwa116">https://forms.gle/MhTPQtRd5RfJwa116</a>
            </p>
            {/* <div className="py-3">
              <Link
                to="/events"
                className="font-bold hover:text-yellow-400 transition duration-300 ease-in-out"
              >
                View Event Details ⏵
              </Link>
            </div> */}
          </div>
        </div>
        <div className="text-center mt-7">
          <Link
            to="/events"
            className="font-bold p-3 pt-2 text-white bg-yellow-400 rounded-md baseline hover:bg-red-600 transition duration-300 ease-in-out mt-6 mr-4"
          >
            Go to Events
          </Link>
        </div>
      </section>
      <section className="container mx-auto pt-6 lg:px-16">
        <h3 className="text-4xl font-bold my-7 text-center">Recent Blogs</h3>
        <div className="block sm:grid sm:grid-cols-2 md:grid-cols-3 gap-6 space-y-6 sm:space-y-0 my-6">
        {latestThree.map(blog => (
          <BlogItem key={blog.id} id={blog.id} img={blog.img} title={blog.title} author={blog.author} datetime={blog.datetime} />
        ))}
        </div>
        {/* <div className="block sm:grid sm:grid-cols-2 md:grid-cols-3 gap-6 space-y-6 sm:space-y-0 my-6">
          <div className="w-11/12 mx-auto md:w-full hover:shadow-lg border hover:border-0 rounded-xl p-6 transition duration-300 ease-in-out">
            <img
              src="images/unleash-your-potential.jpg"
              alt=""
              className="h-60 w-full rounded-xl object-cover"
            />
            <h6 className="hover:text-red-600 my-3 font-bold">
              Unleashing Your Potential: Empowering Youth to Reach for the Stars
            </h6>
            <div className="flex justify-between">
              <span className="self-left">
                By <b>Michelle Shawn</b>
              </span>
              <span className="self-right">10 hours ago</span>
            </div>
          </div>
          <div className="w-11/12 mx-auto md:w-full hover:shadow-lg border hover:border-0 rounded-xl p-6 transition duration-300 ease-in-out">
            <img
              src="images/trailblazers-of-tomorrow.jpg"
              alt=""
              className="h-60 w-full rounded-xl object-cover"
            />
            <h6 className="hover:text-red-600 my-3 font-bold">
              Trailblazers of Tomorrow: Stories of Young Changemakers Making a
              Difference
            </h6>
            <div className="flex justify-between">
              <span className="self-left">
                By <b>Richard Rodriquez</b>
              </span>
              <span className="self-right">21 hours ago</span>
            </div>
          </div>
          <div className="w-11/12 mx-auto md:w-full hover:shadow-lg border hover:border-0 rounded-xl p-6 transition duration-300 ease-in-out">
            <img
              src="images/navigating-the-road-to-success.jpg"
              alt=""
              className="h-60 w-full rounded-xl object-cover"
            />
            <h6 className="hover:text-red-600 my-3 font-bold">
              Navigating the Road to Success: Inspiring Youth to Dream, Believe,
              and Achieve
            </h6>
            <div className="flex justify-between">
              <span className="self-left">
                By <b>Ariel Chaw</b>
              </span>
              <span className="self-right">2 days ago</span>
            </div>
          </div>
        </div> */}
        <div className="text-center mb-6">
          <a
            href=""
            className="font-bold p-3 pt-2 text-white bg-yellow-400 rounded-md baseline hover:bg-red-600 transition duration-300 ease-in-out mt-6 mr-4"
          >
            View More Blogs
          </a>
        </div>
      </section>
      <section className="w-11/12 md:w-full container mx-auto lg:px-16 bg-dark-blue rounded-3xl text-white p-4 my-12">
        <h3 className="text-4xl font-bold mb-2 text-center">
          Join & Help in making an <span className="text-red-600">Impact!</span>
        </h3>
        <p className="text-center my-3">
          Keep up with the latest news, blogs and events by joining us and
          helping us by spreading it on social media.
        </p>
        <div className="flex justify-center">
          <div className="space-x-5">
            <img className="inline" src="images/Facebook_Logo.svg" alt="" />
            <img className="inline" src="images/Instagram_Logo.svg" alt="" />
            <img className="inline" src="images/Youtube_Logo.svg" alt="" />
          </div>
        </div>

        <form
          action=""
          className="block md:flex justify-center md:space-x-10 my-4 space-y-4 md:space-y-0"
        >
          <div className="space-y-4">
            <input
              type="text"
              placeholder="Full Name"
              name="fullname"
              id="fullname"
              className="block rounded-lg p-3 w-full md:w-64 text-black"
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              id="email"
              className="block rounded-lg p-3 w-full md:w-64 text-black"
            />
            <input
              type="number"
              placeholder="Phone Number"
              name="phonenumber"
              id="phonenumber"
              className="block rounded-lg p-3 w-full md:w-64 text-black"
            />
          </div>
          <div>
            <textarea
              name="message"
              placeholder="Message"
              id=""
              cols="30"
              // rows="7"
              className="rounded-lg p-3 w-full h-44 text-black"
            ></textarea>
          </div>
        </form>
        <div className="text-center mb-6">
          <a
            href=""
            className="font-bold p-3 pt-2 text-white bg-yellow-400 rounded-md baseline hover:bg-red-600 mt-6 transition duration-300 ease-in-out mr-4"
          >
            Join Us
          </a>
        </div>
      </section>
      <section id="contactus" className="header-bg-contact mx-auto py-8 bg-cover md:bg-auto bg-no-repeat bg-center">
        <h3 className="text-4xl font-bold my-8 text-center text-white">
          Or Contact Us
        </h3>
        <div className="block md:flex justify-around space-y-4 md:space-y-0 text-white mb-16">
          <div className="mx-auto md:w-1/3">
            <div className="rounded-full bg-dark-blue h-32 w-32 mx-auto flex items-center justify-center">
              <img
                src="images/mobile.svg"
                alt=""
                className="h-12 item self-center"
              />
            </div>
            <h5 className="text-2xl font-bold text-center my-3">Phone</h5>
            <p className="text-center">1-289-697-5919</p>
          </div>
          <div className="mx-auto md:w-1/3">
            <div className="rounded-full bg-dark-blue h-32 w-32 mx-auto flex items-center justify-center">
              <img src="images/sms.svg" alt="" className="h-12" />
            </div>
            <h5 className="text-2xl font-bold text-center my-3">Email</h5>
            <p className="text-center">impactyoutheens@gmil.com</p>
          </div>
          <div className="mx-auto md:w-1/3 ">
            <div className="rounded-full bg-dark-blue h-32 w-32 mx-auto flex items-center justify-center">
              <img src="images/location.svg" alt="" className="h-12" />
            </div>
            <h5 className="text-2xl font-bold text-center my-3">Location</h5>
            <p className="text-center">Kitchener, ON, Canada.</p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;

import React from "react";

function AboutHeader() {
  return (
    <header className="header-bg-aboutus bg-cover bg-no-repeat bg-center md:bg-right bg-local bg-blend-color-burn flex justify-center items-center text-white">
      <div className="w-5/6 md:w-3/5">
        <h2 className="text-center text-5xl font-bold py-3">About Us</h2>
        <p className="text-center text-xl">
          Impact Youtheens is a youth organization that equips young people with
          the right knowledge and skills needed to lead better lives while
          contributing positively to the sustainable development of their community.
        </p>
      </div>
    </header>
  );
}

export default AboutHeader;

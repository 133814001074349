import React, { useState } from "react";
import Nav from "../../components/Nav";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

function PitchEvent() {
  return (
    <div>
      <Nav />

      <div className="bg-dark-blue text-white">
        <div
          className="container mx-auto lg:px-16 bg-darkblue pt-16"
        >
          <div className="grid md:grid-cols-2 pb-8 text-lg">
            <div className="text-left mt-4 space-y-5 self-center">
              <h2 className="text-3xl">
                🌈🌟 <b className="text-red-600">Join</b> the Exciting Youth
                Competition by{" "}
                <b className="text-yellow-500">Rainbow Youtheens Ministry!</b> 🌟🌈
              </h2>
              <p className="my-4 font-bold">
                Calling all talented young minds aged 15-25!!!
              </p>
              <p>
                Are you passionate about IT, Business/ Entrepreneurship, or
                Creativity? Do you have an existing business? We’ve got an
                exciting opportunity just for you!{" "}
              </p>
              <p>
                Get ready for an electrifying showcase of talent and skill in
                the most awaited competition of the year.
              </p>
              <p>
                🔹 <b>Theme:</b> PITCH UP: SKILL HIGHER.
                <br />
                🔹 <b>Categories for Entry:</b> IT, General Business, and
                Creativity.
              </p>
              <p className=" my-4">
                📅 Entry Period: <b className="text-red-600">10 August</b>
                <b> - </b>
                <b className="text-red-600">10 September, 2023</b>.
              </p>
              <div className="flex-left">
                <a
                  className="font-bold p-3 pt-2 text-white bg-yellow-400 rounded-md baseline hover:bg-red-600 transition duration-300 ease-in-out mt-10 mr-4"
                  href="#register-pitch"
                >
                  <b>Register Now</b>
                </a>
              </div>
            </div>
            <div className="">
              <img
                src="images/Pitch-Up-Flyer.jpg"
                alt="poster"
                className="shadow w-9/12 mt-3 rounded-3xl mx-auto"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto lg:px-16 text-lg">
        <div className="grid md:grid-cols-2 mt-3">
          <div className="w-11/12 text-left mt-8 ml-auto">
            <p>
              🌟 What's in it for you? <br />
              <b className="text-yellow-500">₦50,000</b> cash price for the winner in
              each category.
            </p>
            <p>
              🏆 Showcase Your Talent. Demonstrate your expertise in IT, impress
              us with your business acumen, or captivate the audience with your
              creative prowess. 🌐💼🗣️📝
            </p>
            <p>
              <b className="text-red-600">*</b>{" "}
              <b>For Youth Between Ages 15 - 25</b>
            </p>
          </div>
          <div className="mt-5">
            <img
              src="images/male-athlete-holding-medal.jpg"
              alt="poster"
              style={{ borderRadius: "15px", width: "65%" }}
              className="shadow mx-auto"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 mb-5">
          <div className="mt-3">
            <img
              src="images/do-something-great.jpg"
              alt="poster"
              style={{ borderRadius: "15px", width: "65%" }}
              className="shadow mt-3 mx-auto"
            />
          </div>
          <div className="mt-8 mx-auto md:mr-auto w-11/12 md:w-10/12">
            <p>
              🌈 Unleash Your Potential: This competition is your chance to
              unleash your true potential and shine brightly among your peers.
              🌟
            </p>
            <p id="register-pitch">
              💡 Expert Judging Panel: Our panel of esteemed judges comprises
              industry experts and experienced speakers who will provide
              valuable feedback and support to help you grow. 🤝
            </p>
          </div>
        </div>

        <div className="row text-start w-10/12 md:w-full mx-auto mt-6">
          <h3 className="text-center mb-3">
            <b className="text-xl">HOW TO PARTICIPATE?</b>
          </h3>
          <div className="col-sm-12 col-md-9 col-lg-9 mx-auto text-left md:w-9/12 mx-auto">
            <ol className="list-decimal">
              <li className="">
                Choose Your Category: Decide whether you want to showcase your
                IT skills, business acumen, or your creative prowess.
              </li>
              <li>
                Create Your Pitch: Prepare an innovative project, business plan
                in a <b>1 - 3 mins</b> video.
              </li>
              <li>
                Submit Your Entry: Send in your submission to us{" "}
                <b className="text-yellow-500">
                  <a
                    href="https://forms.gle/8L9BvM7rXgTNSxhc7"
                    rel="noreferrer"
                  >
                    here{" "}
                  </a>
                </b>
                before the deadline. Don't miss the opportunity to be a part of
                this thrilling competition!
              </li>
            </ol>
            <h5 className="text-center my-4">
              Entry opens <b className="text-red-600">August 10</b> & closes{" "}
              <b className="text-red-600">September 10, 2023</b>.
            </h5>
            <p>
              🎉 Don't miss out on this amazing chance to elevate your skills
              and win fantastic prizes! Join the Rainbow Youtheens Ministry's
              Pitch up:skill higher competition today! 🎉
            </p>
            <p>
              📧 For any queries, contact us via our Facebook:{" "}
              <a
                href="https://www.facebook.com/rainbowyoutheens/"
                rel="noreferrer"
                target="_blank"
              >
                RAINBOW Youtheens
              </a>{" "}
              and Instagram:{" "}
              <a
                href="https://www.instagram.com/rainbowyoutheens/?igshid=YmMyMTA2M2Y%3D"
                rel="noreferrer"
                target="_blank"
              >
                @rainbowyoutheens
              </a>{" "}
              or email us at{" "}
              <a href="mailto:rainbowyoutheens@gmail.com">
                rainbowyoutheens@gmail.com
              </a>
              . Go to{" "}
              <b>
                <Link to="/contact">Contact Us</Link>
              </b>{" "}
              for more details.
            </p>
            <p>
              🌟 Unlock your potential, rise to the challenge, and let your
              skills soar to new heights! See you at the competition!
            </p>
            <span className="flex-left mb-4">
              <a
                href="https://forms.gle/8L9BvM7rXgTNSxhc7"
                rel="noreferrer"
                style={{ textDecoration: "none", fontSize: "18px" }}
                className="btn-1 button-bg text-white"
              >
                <b>Register Here</b>
              </a>
            </span>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PitchEvent;

import { DateTime } from "luxon";

export const daysCountDown = (datetime) => {
  const postDate = DateTime.fromISO(datetime);
  const now = DateTime.local();
  const diff = now.diff(postDate);

  let formattedString;

  if (diff.as("years") >= 1) {
    formattedString = `${Math.floor(diff.as("years"))} years ago`;
  } else if (diff.as("months") >= 1) {
    formattedString = `${Math.floor(diff.as("months"))} months ago`;
  } else if (diff.as("days") >= 1) {
    formattedString = `${Math.floor(diff.as("days"))} days ago`;
  } else if (diff.as("hours") >= 1) {
    formattedString = `${Math.floor(diff.as("hours"))} hours ago`;
  } else if (diff.as("minutes") >= 1) {
    formattedString = `${Math.floor(diff.as("minutes"))} minutes ago`;
  } else {
    formattedString = "Just now";
  }

  return (formattedString);
};

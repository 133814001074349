import logo from './logo.svg';
import './App.css'; 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Blogs from './pages/Blogs';
import SingleBlog from './pages/SingleBlog'
import Events from './pages/Events';
import PitchEvent from './pages/PitchEvent';
import Gallery from './pages/Gallery'
import Donate from './pages/Donate'

function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/events" element={<Events />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/:id" element={<SingleBlog />} />
        <Route path='/pitch-event' element={<PitchEvent />} />
        {/* <Route path='/donate' element={<Donate />} /> */}
      </Routes>
    </Router>
    </div>
  );
}

export default App;

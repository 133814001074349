import React, { useEffect, useState } from "react";
import Nav from "../../components/Nav";
import EventsHeader from "../../components/EventsHeader";
import eventsData from "../../data/events.json";
import EventItem from "../../components/EventItem";
import Footer from "../../components/Footer";

function Events() {
  console.log(eventsData);
  // const [limitedText, setLimitedText] = useState("");

  return (
    <div>
      <Nav />
      <EventsHeader />
      <div className="container mx-auto lg:px-16 py-4 w-11/12 md:w-full">
        <h4 className="text-3xl font-bold my-4">All Events</h4>
        <hr className="bg-black h-0.5" />
        <div className="space-y-4 my-3">
          {eventsData.map((event) => (
            <EventItem
              key={event.id}
              id={event.id}
              datetime={event.datetime}
              venue={event.venue}
              link={event.link}
              theme={event.theme}
              title={event.title}
              about={event.about}
              image={event.image}
              extraImages={event.extraImages}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Events;

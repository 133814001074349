import React from "react";
import { daysCountDown } from "../../utils/dayscountdown";
import { Link } from "react-router-dom";

function BlogItem({id, img, title, author, datetime}) {
  const formattedString = daysCountDown(datetime);

  return (
    <div className="w-11/12 mx-auto md:w-full hover:shadow-lg border hover:border-0 rounded-xl p-6 transition duration-300 ease-in-out">
      <Link to={`/blog/${id}`} >
        <img
          src={img}
          alt=""
          className="h-60 w-full rounded-xl object-cover"
        />
        <h6 className="hover:text-red-600 my-3 font-bold">
          {title}
        </h6>
        <div className="flex justify-between">
          <span className="self-left">
            By <b>{author}</b>
          </span>
          <span className="self-right">{formattedString}</span>
        </div>

      </Link>
    </div>
  );
}

export default BlogItem;

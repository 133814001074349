import React from 'react'

function Gallery() {
  return (
    <div>
      Not currently available
    </div>
  )
}

export default Gallery
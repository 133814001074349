import React from 'react'
import BlogsHeader from '../../components/BlogsHeader'
import Nav from '../../components/Nav'
import Footer from '../../components/Footer'
import BlogItem from '../../components/BlogItem'
import jsonData from '../../data/blog.json';

function Blogs() {
  const blogs = jsonData.blogs;

  return (
    <div>
      <Nav textCol={`text-white`} />
      <BlogsHeader />
      <section className="w-11/12 sm:container mx-auto lg:px-16 pt-6">
        <div className="block sm:grid sm:grid-cols-2 md:grid-cols-3 gap-6 space-y-6 sm:space-y-0 my-6">
        {blogs.map(blog => (
          <BlogItem key={blog.id} id={blog.id} img={blog.img} title={blog.title} author={blog.author} datetime={blog.datetime} />
        ))}
        </div>
        <div className="text-center mb-6">
          <a
            href=""
            className="font-bold p-3 pt-2 text-white bg-yellow-400 rounded-md baseline hover:bg-red-600 transition duration-300 ease-in-out mt-6 mr-4"
          >
            Load older blogs
          </a>
        </div>
      </section>
      <section className="w-11/12 md:w-full container mx-auto lg:px-16 bg-dark-blue rounded-3xl text-white p-4 mt-12 mb-8">
        <h3 className="text-4xl font-bold mb-2 text-center">
        Interested in writing <span className="text-red-600">blogs</span>?
        </h3>
        <p className="text-center my-3">
        Join us or send us a message on social media stating your interest in blogging for Impact Youtheens.
        </p>
        <div className="flex justify-center">
          <div className="space-x-5">
            <img className="inline" src="images/Facebook_Logo.svg" alt="" />
            <img className="inline" src="images/Instagram_Logo.svg" alt="" />
            <img className="inline" src="images/Youtube_Logo.svg" alt="" />
          </div>
        </div>
        <div className="text-center my-6 mt-8">
          <a
            href=""
            className="font-bold p-3 pt-2 text-white bg-yellow-400 rounded-md baseline hover:bg-red-600 mt-6 transition duration-300 ease-in-out mr-4"
          >
            Join Us
          </a>
        </div>
      </section>  
      <Footer />
    </div>
  )
}

export default Blogs
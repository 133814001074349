import React from "react";

function EventDetails({
  displayDetails,
  sendDatatoParent,
  title,
  theme,
  about,
  extraImages,
  reg_link
}) {
  const sendDatatoParentComp = () => {
    sendDatatoParent("hidden");
  };
  return (
    <div className={`absolute bg-white ${displayDetails} z-20`}>
      <div className="fixed w-10/12 md:w-9/12 top-20 bg-white p-4 rounded-2xl shadow-xl space-y-5 transition duration-700 ease-in-out overflow-hidden">
        <h4 className="font-bold text-3xl">
          {theme}: <span className="text-red-600">{title}</span>
        </h4>
        <hr />
        <div className="h-80 overflow-y-auto">
          <p className="whitespace-pre-line">{about}</p>
          {reg_link && (
            <a className="text-blue-600" href={reg_link}>{reg_link}</a>
          )}
          {extraImages && (
          <div className={`grid sm:grid-cols-3 grid-cols-1 gap-4`}>
            {extraImages.map((imagelink, index) => (
              <img className="rounded-lg" src={`${imagelink}`} key={index} alt={`${title} ${index}`} />
            ))}
          </div>
        )}
        </div>
        <button
          className="font-bold p-3 pt-2 text-white bg-yellow-400 rounded-md baseline hover:bg-red-600 transition duration-300 ease-in-out mt-6 mr-4"
          onClick={sendDatatoParentComp}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default EventDetails;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import EventDetails from "../EventDetails";

function EventItem(props) {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");

  const [displayDetails, setDisplayDetails] = useState("hidden");

  const handleDisplay = () => {
    setDisplayDetails("block");
  };

  const handleDetailsClose = (data) => {
    setDisplayDetails(data);
  }

  function limitText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
  }

  useEffect(() => {
    const dateObj = DateTime.fromISO(props.datetime);

    setDay(dateObj.day);
    setMonth(dateObj.toFormat("LLL"));
  }, [props.datetime]);

  return (
    <div className="block md:grid grid-cols-2 gap-8 justify-between md:space-x-12 p-4 rounded-3xl hover:border-2">
      <div className="flex justify-start md:justify-between px-4 md:px-0 space-x-3">
        <div className="w-1/4">
          <h2 className="text-4xl font-bold capitalize">{month}</h2>
          <div className="h-1 w-2/4 bg-red-500 rounded-full my-3"></div>
          <h1 className="text-6xl font-bold">{day}</h1>
        </div>
        <div className="w-full h-56 sm:h-72 md:h-80">
          <img
            src={props.image}
            alt={props.title}
            className="w-fit h-full object-cover rounded-xl shadow-lg mx-auto"
          />
        </div>
      </div>
      <div className="w-11/12 mx-auto pt-5 md:pt-0">
        <h4 className="font-bold text-3xl ">{props.theme}</h4>
        <h4 className="font-bold text-2xl text-red-600">{props.title}</h4>
        <p className="leading-8 font-bold">{props.venue}</p>
        <p className="leading-8 font-bold">
          {DateTime.fromISO(props.datetime).toFormat(
            "ccc, LLL dd, yyyy hh:mm a"
          )}
        </p>
        <p className="leading-8">{limitText(props.about,250)}</p>
        <div className="py-3">
          <button
            onClick={handleDisplay}
            className="font-bold hover:text-yellow-400 transition duration-300 ease-in-out"
          >
            View Event Details ⏵
          </button>
        </div>
      </div>
      <EventDetails displayDetails={displayDetails} sendDatatoParent={handleDetailsClose} about={props.about} title={props.title} theme={props.theme} extraImages={props.extraImages} reg_link={props.reg_link} />
    </div>
  );
}

export default EventItem;

import React from "react";

export default function EventsHeader() {
  return (
    <div className="header-bg-events bg-no-repeat bg-bottom bg-local flex justify-center items-center text-white">
      <div className="w-3/5">
        <h2 className="text-center text-5xl font-bold py-3">Events</h2>
        <p className="text-center text-xl">From heartwarming discussions to exciting workshops, our calendar is filled with opportunities to explore, learn, and celebrate together. Join us as we embark on a journey of empowerment, learning, and fun.</p>     
      </div>
      
    </div>
  );
}

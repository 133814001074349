import React, { useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const [isOpen, setIsOpen] = useState(false); // Use boolean for isOpen

  // WWD - What We Do
  const handleWWDDropDown = () => {
    setIsOpen(!isOpen); // Toggle isOpen between true and false
  };

  const whatWeDoMenu = (
    <div
      className={`fixed bottom-24 sm:bottom-36 md:bottom-32 p-2 w-48 bg-white rounded-md text-md shadow-xl z-50 space-y-3 font-bold ${
        isOpen ? "opacity-100 translate-y-0 visible" : "opacity-0 translate-y-[-10px] invisible"
      } transition-transform duration-300 ease-in-out`}
    >
      <Link
        to="/blog"
        className="block px-4 py-2 capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out hover:text-white rounded-md"
      >
        Blogs
      </Link>
      <Link
        to="/events"
        className="block px-4 py-2 capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out
            hover:text-white rounded-md"
      >
        Events
      </Link>
      {/* <Link
        to="/gallery"
        className="block px-4 py-2 capitalize text-black hover:bg-yellow-400 transition duration-300 ease-in-out
            hover:text-white rounded-md"
      >
        Gallery
      </Link> */}
    </div>
  );

  return (
    <footer className="bg-dark-blue py-3 pb-5">
      <div className="px-3 md:px-0 md:container mx-auto block md:flex justify-between items-center">
        <div>
          <img src="/images/logo.svg" alt="" className="w-5/12 sm:w-full" />
        </div>
        <div className="block md:flex space-x-0 md:space-x-6 px-3 md:px-0 text-white">
          <Link
            to="/about"
            className="block w-fit font-bold p-3 hover:text-yellow-400 transition duration-300 ease-in-out"
          >
            About Us
          </Link>
          <button
            onClick={handleWWDDropDown}
            className="block w-fit font-bold p-3 hover:text-yellow-400 transition duration-300 ease-in-out"
          >
            What We Do ⏷{whatWeDoMenu}
          </button>
          {/* <a
            href="/blog"
            className="block w-fit font-bold p-3 hover:text-yellow-400 transition duration-300 ease-in-out"
          >
            Blogs
          </a> */}
          <a
            href="https://buy.stripe.com/7sI28tfO42US2as3cc"
            target="_blank"
            rel="noreferrer noopener"
            className="block w-fit font-bold p-3 my-auto pt-2 bg-yellow-400 rounded-md baseline hover:bg-red-600 transition duration-300 ease-in-out text-center"
          >
            Support Us
          </a>
          <div className="space-x-5 my-auto mt-5 md:mt-3">
            <a href="https://facebook.com/impactyoutheens">
              <img className="inline" src="images/Facebook_Logo.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/impact_youtheenscanada">
              <img className="inline" src="images/Instagram_Logo.svg" alt="" />
            </a>
            <a href="https://www.youtube.com/@rainbowyoutheensministry4201">
              <img className="inline" src="images/Youtube_Logo.svg" alt="" />
            </a>
          </div>
        </div>
        <div className="w-16 ml-auto md:ml-0">
          <a
            href="#"
            className="p-3 my-auto pt-2 bg-yellow-400 rounded-md inline-block text-center hover:bg-red-600 transition duration-300 ease-in-out"
          >
            <img src="/images/direct-down.svg" alt="" className="" />
          </a>
        </div>
      </div>
      <p className="text-center container mx-auto my-3 text-white">
        Copyright © {new Date().getFullYear()}, Impact Youtheens
      </p>
    </footer>
  );
}

export default Footer;

import React from "react";

function BlogsHeader() {
  return (
    <header className="header-bg-blogs bg-no-repeat bg-center bg-local bg-blend-darken flex justify-center items-center text-white">
      <div className="w-3/5">
        <h2 className="text-center text-5xl font-bold py-3">Blogs</h2>
        <p className="text-center text-xl">All news about blogs in one place</p>
        <div className="mx-auto search-width mt-20">
          <form
            action=""
            className="bg-white mx-auto my-auto rounded-full flex"
          >
            <input
              type="search"
              name="search-blogs"
              id="search-blogs"
              placeholder="Search by keywords"
              className="w-full ml-4 my-3 focus:outline-none text-black"
            />
            <button type="submit" className="ml-2 mr-3">
              <img src="images/search-normal.svg" alt="" className="" />
            </button>
          </form>
        </div>
      </div>
    </header>
  );
}

export default BlogsHeader;

import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header className="header-bg-image bg-no-repeat bg-center md:bg-left-top bg-local md:h-full h-screen">
      <div className="flex items-center justify-center md:grid md:grid-cols-2 gap-12 h-full container mx-auto lg:px-16 pt-24">
        <div
          className="w-11/12 mx-auto md:w-full rounded-lg backdrop-blur-md text-white px-8 py-2"
          style={{ backgroundColor: `rgba(4,4,83,0.18)` }}
        >
          <h3 className=" text-4xl lg:text-5xl text-center mb-4 pt-10 ">
            We are here to leave an
          </h3>
          <h2 className="font-bold text-yellow-400 text-5xl text-center my-4">
            IMPACT
          </h2>
          <p className="my-9 text-lg">
            Welcome to Impact Youtheens! We empower youth with vital
            knowledge for community impact.
          </p>
          <div className="mb-9">
            <a
              href="https://buy.stripe.com/7sI28tfO42US2as3cc"
              target="_blank"
              rel="noreferrer noopener"
              className="font-bold p-3 pt-2 bg-yellow-400 rounded-md baseline hover:bg-red-800 text-center mt-6 mr-4 transition duration-300 ease-in-out"
            >
              Donate Now
            </a>
            <a
              href="https://forms.gle/VtvKvErdWqExRHYi7"
              target="_blank"
              rel="noreferrer noopener"
              className="font-bold p-3 pt-2 bg-dark-blue rounded-md baseline hover:bg-red-800 text-center mt-6 transition duration-300 ease-in-out"
            >
              Join Us
            </a>
          </div>
        </div>
      </div>
      <section className="hidden md:block bg-gradient-to-b from-transparent to-[#040453] to-70% py-12">
        <div className="block md:grid grid-cols-3 gap-12 container mx-auto lg:px-16">
          <div
            className="w-full h-80 rounded-lg bg-slate-100 text-white px-8 text-center py-6"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
          >
            <img className="mx-auto" src="images/mission.svg" alt="" />
            <h5 className="font-bold text-xl my-4">Embrace Impact</h5>
            <p className="mb-9">
              Discover the transformative power of embracing impact. Know more
              today.
            </p>
            <div>
              <Link
                to="/about"
                className="font-bold p-3 pt-2 border-2 border-dark-blue hover:bg-dark-blue rounded-md baseline mt-6 transition duration-300 ease-in-out"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div
            className="w-full h-80 rounded-lg bg-slate-100 text-white px-8 text-center py-6"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
          >
            <img src="images/charity.svg" alt="" className="mx-auto" />
            <h5 className="font-bold text-xl my-4">Volunteer</h5>
            <p className="mb-9">
              Together, let's inspire, support, and empower the youth.
            </p>
            <a
              href="https://facebook.com/impactyoutheens"
              className="font-bold p-3 pt-2 border-2 border-yellow-400 hover:bg-yellow-400 rounded-md baseline mt-6 transition duration-300 ease-in-out"
            >
              Volunteer
            </a>
          </div>
          <div
            className="w-full h-80 rounded-lg bg-slate-100 text-white px-8 text-center py-6"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
          >
            <img src="images/charity.svg" alt="" className="mx-auto" />
            <h5 className="font-bold text-xl my-4">Connect with Us</h5>
            <p className="mb-9">
              Connect with us today and be a part of something incredible!
            </p>
            <a
              href="#contactus"
              className="font-bold p-3 pt-2 border-2 border-dark-blue hover:bg-dark-blue rounded-md baseline mt-6 transition duration-300 ease-in-out"
            >
              Contact Us
            </a>
          </div>
        </div>
      </section>
    </header>
  );
}

export default Header;

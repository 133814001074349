import React, { useState} from "react";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import AboutHeader from "../../components/AboutHeader";
import TeamItem from "../../components/TeamItem";

function AboutUs() {
  const [divIndex, setDivIndex] = useState(1);
  const [clickedButton, setClickedButton] = useState(null);

  const arrTeam = [
    {
      name: "Favour Ogbeide",
      role: "IT Manager",
      image: "/images/sir-favour.jpeg",
    },
    {
      name: "Adrienne Frances",
      role: "Content Creator",
      image: "/images/adrienne-frances.jpg",
    },
    {
      name: "Fortune Precious",
      role: "Software Engineer",
      image: "/images/fortune-precious.jpg",
    },
  ];

  // const desc = [
  //   "Providing comprehensive educational programs and skill-building initiatives that empower young people with the knowledge and practical skills necessary for personal growth, career development, and community engagement.",
  //   "Fostering leadership qualities and empowering young individuals to become agents of positive change in their communities. We strive to enhance their confidence, decision-making abilities, and problem-solving skills, enabling them to address local challenges effectively.",
  //   "Encouraging young people to actively engage with their communities and become catalysts for sustainable development. Through collaborative projects, we aim to address social, environmental, and economic issues, promoting a holistic approach towards community betterment.",
  //   "Advocating for youth rights, equal opportunities, and policies that support the needs and aspirations of young people. We work to amplify youth voices, influencing decision-makers and stakeholders to create an enabling environment for youth development and participation.",
  //   "We value and celebrate the diversity of young individuals. Our organization is committed to fostering an inclusive environment where all young people feel welcome, respected, and valued. We strive to create spaces that embrace diversity, promote equality, and encourage cross-cultural understanding and collaboration.",
  //   "Building a vibrant and inclusive network of young individuals, organizations, and stakeholders committed to youth empowerment and sustainable development. We foster collaboration, knowledge sharing, and mutual support to maximize our collective impact.",
  //   "Promoting research and innovation to continuously adapt and enhance our programs and approaches. By staying informed about emerging trends, challenges, and opportunities, we ensure that our organization remains at the forefront of youth empowerment and sustainable development.",
  // ];

  // const [displayText, setDisplayText] = useState(desc[0]);

  // const aims = <p className="">{displayText}</p>;

  // const handleAimChange = (text, buttonIndex) => {
  //   setDisplayText(text);
  //   setClickedButton(buttonIndex);
  //   setDivIndex(buttonIndex);
  // };

  // const getButtonClass = (buttonIndex) => {
  //   return buttonIndex === clickedButton ? "text-red-600" : "";
  // };

  return (
    <div>
      <Nav textCol={`text-white`} />
      <AboutHeader />
      <section className="block md:grid grid-cols-3 gap-10 py-10 w-10/12 mx-auto leading-8 space-y-4 md:space-y-0">
        <p className="pr-8 my-auto text-lg leading-10">
          <span className="font-bold text-red-600">Join us</span> in our journey
          to empower youth, drive positive change, and make a lasting impact.
          Together, we can create a brighter future for generations to come.
        </p>
        <div className="p-5 bg-dark-blue text-white text-center rounded-2xl">
          <h6 className="text-2xl">VISION</h6>
          <p>
            To create a future where every young person is equipped with the
            knowledge, skills, and opportunities to lead fulfilling lives and
            make meaningful contributions to the sustainable development of
            their communities.
          </p>
        </div>
        <div className="p-5 bg-dark-blue text-white text-center rounded-2xl">
          <h6 className="text-2xl">MISSION</h6>
          <p>
            Our mission is to empower and uplift young individuals by providing
            them with the tools, resources, and opportunities needed to unlock
            their potential, foster personal growth, and become active
            contributors to positive change in their lives and communities.
          </p>
        </div>
      </section>
      <section>
        <h3 className="font-bold text-4xl text-red-600 text-center">
          We aim to achieve this by:
        </h3>
        <div className="block w-11/12 md:grid grid-cols-2 gap-4 container mx-auto lg: px-16 my-auto">
          <div className="space-y-4 mx-auto my-auto">
            <div>
              <h6 className={`text-2xl`}>⦁ Education and Skill Development</h6>
            </div>
            <div>
              <h6 className={`text-2xl`}>⦁ Leadership and Empowerment</h6>
            </div>
            <div>
              <h6 className={`text-2xl`}>⦁ Advocacy and Policy Influence</h6>
            </div>
            <div>
              <h6 className={`text-2xl`}>⦁ Inclusivity and Diversity</h6>
            </div>
            <div>
              <h6 className={`text-2xl`}>⦁ Network and Collaboration</h6>
            </div>
            <div>
              <h6 className={`text-2xl`}>⦁ Research and Innovation</h6>
            </div>
            <div>
              <h6 className={`text-2xl text-left`}>
                ⦁ Community Engagement and Sustainable Development
              </h6>
            </div>
          </div>
          <div className="p-4">
            <img
              src="images/happy-teenagers-posing-together.jpg"
              alt="Happy teenagers posing together"
              className="rounded-2xl shadow-lg h-9/12 w-9/12 mx-auto"
            />
          </div>
        </div>
      </section>
      <section className="w-11/12 md:w-full container mx-auto lg:px-16 rounded-3xl bg-dark-blue my-8">
        <p className="w-5/6 mx-auto py-10 text-white text-center text-2xl leading-8">
          Through our vision and mission, we strive to create a world where
          young people are equipped with the necessary tools, resources, and
          support to lead meaningful lives and actively contribute to building
          thriving and sustainable communities.
        </p>
      </section>
      <section className="container mx-auto lg:px-16 w-11/12 md:w-full">
        <h3 className="font-bold text-4xl text-red-600 text-center">
          Meet Our President
        </h3>
        <div className="md:grid grid-cols-3 gap-4 my-3">
          <div className="md:col-span-1">
            <img
              className="rounded-xl shadow-md w-2/3 md:w-3/3 lg:w-/3 mx-auto md:ml-auto"
              src="/images/aunty_kelechi.jpg"
              alt="Aunty Kelechi"
            />
          </div>
          <div className="col-span-2">
            <p className="p-4 rounded-xl shadow-md leading-relaxed my-auto">
              I am an award-winning youth life coach and consultant, and I am
              passionate about empowering and guiding young individuals to
              discover their true potential and lead fulfilling lives. As a
              dedicated youth life coach, I am committed to making a positive
              impact by helping young people navigate the challenges they face
              and supporting them in their personal, academic and career growth.
              <br />
              <br />
              With 15 years of experience working with young people, I have
              developed a deep understanding of their unique needs and
              aspirations.
              <br />
              <br />
              My coaching approach is built on trust, empathy, and active
              listening, creating a safe and non-judgmental space for the youth
              to explore their thoughts, feelings, and goals.
              <br />
              <br />
              My mission is simple: I plant the child, train the teenager, and
              prepare the youth.I hold the prestigious Unsung Heroes Award for
              Best Teens Mentor, She Leads(BBC Africa) Award, PFN Award, the
              Outstanding Dignified Personality Award, etc. You can contact me
              via email:
              <a className="text-blue-600" href="mailto:lindaemihe@gmail.com">
                {" "}
                lindaemihe@gmail.com
              </a>
            </p>
          </div>
        </div>
      </section>
      <section className="container mx-auto lg:px-16 my-6">
        <h3 className="font-bold text-4xl text-red-600 text-center">
          Meet Our Team
        </h3>
        <p className="w-5/6 mx-auto text-lg text-center my-3">
          We are proud to have a diverse and talented team of individuals who
          are passionate about what they do. Our team is the driving force
          behind our success, combining their unique skills and expertise to
          deliver exceptional results. Get to know the faces behind the work:
        </p>
        <div className="block sm:w-10/12 md:w-8/12 mx-auto sm:grid sm:grid-cols-2 md:grid-cols-3 gap-10 my-6 space-y-6 sm:space-y-0">
          {arrTeam.map((item, index) => (
            <TeamItem
              key={index}
              name={item.name}
              role={item.role}
              text={item.text}
              image={item.image}
              images_link={item.images_link}
            />
          ))}
        </div>
      </section>
      <section className="w-11/12 md:w-full container mx-auto lg:px-16 bg-dark-blue rounded-3xl text-white p-4 my-12">
        <h3 className="text-4xl font-bold mb-2 text-center">
          Join & Help in making an <span className="text-red-600">Impact!</span>
        </h3>
        <p className="text-center my-3">
          Keep up with the latest news, blogs and events by joining us and
          helping us by spreading it on social media.
        </p>
        <div className="flex justify-center">
          <div className="space-x-5">
            <img className="inline" src="images/Facebook_Logo.svg" alt="" />
            <img className="inline" src="images/Instagram_Logo.svg" alt="" />
            <img className="inline" src="images/Youtube_Logo.svg" alt="" />
          </div>
        </div>

        <form
          action=""
          className="block md:flex justify-center md:space-x-10 my-4 space-y-4 md:space-y-0"
        >
          <div className="space-y-4">
            <input
              type="text"
              placeholder="Full Name"
              name="fullname"
              id="fullname"
              className="block rounded-lg p-3 w-full md:w-64 text-black"
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              id="email"
              className="block rounded-lg p-3 w-full md:w-64 text-black"
            />
            <input
              type="number"
              placeholder="Phone Number"
              name="phonenumber"
              id="phonenumber"
              className="block rounded-lg p-3 w-full md:w-64 text-black"
            />
          </div>
          <div>
            <textarea
              name="message"
              placeholder="Message"
              id=""
              cols="30"
              // rows="7"
              className="rounded-lg p-3 w-full h-44 text-black"
            ></textarea>
          </div>
        </form>
        <div className="text-center mb-6">
          <a
            href=""
            className="font-bold p-3 pt-2 text-white bg-yellow-400 rounded-md baseline hover:bg-red-600 mt-6 transition duration-300 ease-in-out mr-4"
          >
            Join Us
          </a>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default AboutUs;

import React, {useState} from "react";
import TeamDetails from "../TeamDetails";

function TeamItem({name, role, text, image, images_link}) {
  const backgroundImageUrl = `url(${image})`;

  // const [displayDetails, setDisplayDetails] = useState("hidden");

  // const handleDisplay = () => {
  //   setDisplayDetails("block");
  // };

  // const handleDetailsClose = (data) => {
  //   setDisplayDetails(data);
  // }

  return (
    <div style={{backgroundImage: backgroundImageUrl}} className="bg-local bg-no-repeat bg-center bg-cover w-10/12 sm:w-full mx-auto h-80 rounded-2xl relative hover:shadow-lg transition duration-300 ease-in-out">
      <div className="absolute bottom-0 left-0 w-full p-2 px-3 bg-dark-blue rounded-b-2xl flex justify-between">
        <div>
          <p className="font-bold text-red-500 text-lg">{name}</p>
          <p className="text-white font-bold">{role}</p>
        </div>
        {/* <button
          onClick={handleDisplay}
          className="p-3 my-auto pt-2 bg-yellow-400 rounded-md inline-block text-center hover:bg-red-600 transition duration-300 ease-in-out"
        >
          <img src="images/direct-right.svg" alt="" className="" />
        </button> */}
      </div>
      {/* <TeamDetails displayDetails={displayDetails} sendDatatoParent={handleDetailsClose} name={name} role={role} images_link={images_link} text={text} /> */}
    </div>
  );
}

export default TeamItem;
